@import "react-alice-carousel/lib/alice-carousel.css";

.experience-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 35vh 5vw 0vh 5vw;
  color: #F8F8F8;
}

.experience-header  {
  width: 60vw;
  margin: 0px 0vw 15px 25vw;
  text-align: right;
  font-size: 2.9em;
  font-weight: 200;
  color: #F8F8F8;
  line-height: 95%;
}

.text-cultural-ed {
  height: 39px;
}

.ex-img  {
  cursor: pointer; 
  transition: all .5s ease-in-out;
  -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
            filter: grayscale(100%); 
}

.ex-img:hover {
  transition: all .5s ease-in-out;
  -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
            filter: grayscale(0%); 
}

.ex-text-container  {
  width: 280px;
  margin-left: 15px;
  border: 15px transparent solid;
}

.ex-header  {
  margin: 0px 0px 0px 0px;
  font-size: 1.5em;
  font-weight: 300;
  color: #F8F8F8;
  line-height: 95%;
}

.ex-subheader {
  margin: 5px 0px 0px 0px;
  font-size: 1em;
  font-weight: 100;
  color: #F8F8F8;
  line-height: 95%;
}

.ex-description {
  width: 255px;
  margin: 25px 0px 10px 0px;
  font-size: 0.85em;
  font-weight: 100;
  color: #909090;
}

.alice-carousel {
  align-self: center;
}

@media (max-width: 600px) {
  .alice-carousel {
    text-align: center;
  }

  .experience-container {
    margin: 35vh 0vw 0vh 0vw;
    align-items: center;
  }

  .experience-header {
    width: 70vw;
    text-align: center;
    font-size: 1.75em;
    margin: 0px 0vw 15px 0vw;
  }
  
  .text-cultural-ed {
    height: 25px;
  }

  .ex-item  {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .ex-header  {
    margin: 15px 0px 0px 0px;
  }
  
  .ex-subheader {
    margin-top: 10px;
  }

  .ex-text-container  {
    width: 275px;
    margin-left: 0px;
    border: none;
  }

  .ex-description {
    max-width: 270px;
    font-size: 1em;
  }

  .ex-description {
    width: 255px;
    margin: 25px 0px 10px 0px;
    font-size: 0.85em;
    font-weight: 200;
    color: #b0b0b0;
  }
}
