.about-container  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 50px;
  margin-top: 180vh;
  padding-top: 50px;
}

.png-em {
  height: 45vh;
}

.about-right-subcontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  width: 50vw;
}

.about-header {
  margin-bottom: 0px;
  font-size: 3em;
  font-weight: 200;
  color: #F8F8F8;
}

.text-em  {
  height: 42px;
  margin: 2px 5px 0px 0px;
}

.about-right, .about-left {
  margin-top: 15px;
  font-size: 0.85em;
  font-weight: 200;
  font-weight: 200;
  color: #909090;
}

.about-right  {
  text-align: right;
}

.about-right-subcontainer {
  width: 65vw;
}

.about-logo-at  {
  height: 12px;
}

@media (max-width: 600px) {
  .about-container  {
    padding-top: 50px;
    margin-top: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 50px;
  }

  .png-em {
    height: 250px;
    width: 250px;
  }

  .about-right  {
    text-align: center;
  }

  .about-header {
    font-size: 1.75em;
  }

  .text-em  {
    height: 25px;
  }
}