body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  background-image: url('./assets/background.png');
  background-size: cover;
  height: fit-content;
}

@media (max-width: 600px) {
  body {
    background-image: url('./assets/mobile-background.png');
    background-repeat: no-repeat;
    background-color: #000000;
  }
}