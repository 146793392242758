.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0vh;
  padding-top: 20px;
  text-align: left;
  font-size: .85em;
  border-top: 2px #383838 solid;
}

@media (max-width: 600px) {
  .footer-container {
    justify-content: flex-start;
    gap: 10%;
  }

  #mobile-hide  {
    display: none;
  }
}