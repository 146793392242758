.story-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30vh;
  color: #F8F8F8;
}

.story-header {
  text-align: center;
  margin: 0px 0px 15px 0px;
  font-size: 3em;
  font-weight: 200;
  line-height: 90%;
}

.story-text {
  text-align: center;
  width: 48vw;
  margin: 0;
  font-weight: 200;
  color: #909090;
}

@media (max-width: 600px) {
  .story-container {
    margin-top: 35vh;
  }

  .story-header {
    font-size: 1.75em;
  }
  .story-text {
    width: 70vw;
  }
}