.portfolio-header  {
  width: 60vw;
  margin: 0px 0vw 15px 0vw;
  text-align: left;
  font-size: 3em;
  font-weight: 200;
  color: #F8F8F8;
  line-height: 95%;
}

.text-front-dev {
  height: 42px;
}

.proj-link-text  {
  margin-right: 15px;
  font-size: 0.85em;
  font-weight: 200;
  color: #F8F8F8;
}

@media (max-width: 600px) {
  .experience-container {
    margin-top: 35vh;
    align-items: center;
    margin: 35vh 5vw 0vh 5vw;
  }

  .portfolio-header {
    width: 70vw;
    text-align: center;
    font-size: 1.75em;
    margin: 0px 0vw 15px 0vw;
  }
  
  .text-front-dev {
    height: 25px;
  }

  .ex-item  {
    margin: 0% 10% 0% 10%;
  }

  .ex-header  {
    text-align: center;
  }

  .ex-subheader {
    text-align: center;
  }

  .ex-description {
    width: 300px;
    text-align: center;
    margin: 10% 0% 0% 0%;
    font-size: 0.9em;
  }

  .proj-links-container {
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
}